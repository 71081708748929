<template>
  <div class="home">
    <div>
      <div class="p-grid p-d-flex p-jc-center">
        <div class="p-col-12 p-d-flex p-jc-center p-mt-5">
          <img class="img-fluid" alt="centro medico integral" src="../assets/puzzle.jpg" />
        </div>
        <div class="p-col-12 p-d-flex p-jc-center">
          <img class="img-fluid" alt="beatriz ayllon" src="../assets/beatriz-ayllon-home.png" />
        </div>


      </div>

      <div class="p-grid">
        <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
          <Card styleClass="p-card-shadow">
            <template #content>
              <p>
                En el <span class="p-text-bold">Centro Médico Beatriz Ayllón</span>, nuestro objetivo principal es brindarte un
                enfoque integral para tu salud emocional y física. Nos especializamos en ayudarte a sentirte bien tanto
                por dentro como por fuera, a través de una amplia gama de terapias de autorregulación emocional y
                tratamientos para el dolor crónico y agudo. Además, ofrecemos servicios de rejuvenecimiento facial y
                corporal que te ayudarán a realzar tu belleza y mejorar tu bienestar general.
              </p>
              <p>
                En el <span class="p-text-bold">Centro Médico Beatriz Ayllón</span>, contamos con un equipo de profesionales
                altamente cualificados en diversas áreas para satisfacer todas tus necesidades. Nuestros expertos en <span
                  class="p-text-bold">Medicina Estética</span> te brindarán los tratamientos más avanzados y seguros para
                realzar tu belleza natural. Nuestros especialistas en <span class="p-text-bold">Estética</span> te
                ofrecerán servicios personalizados para mejorar tu apariencia física y aumentar tu confianza. Nuestros
                psicólogos te acompañarán en tu viaje hacia el bienestar emocional y te proporcionarán herramientas para
                gestionar el estrés y las emociones. Además, nuestros fisioterapeutas te ayudarán a aliviar el dolor y
                mejorar tu movilidad. También ofrecemos servicios de <span class="p-text-bold">Micropigmentación</span>
                para realzar tus rasgos faciales de forma natural y duradera.
              </p>
              <p>
                En el <span class="p-text-bold">Centro Médico Beatriz Ayllón</span>, nos enorgullece brindar un ambiente cálido
                y acogedor, donde te sentirás cómodo y seguro durante todo el proceso. Nuestro enfoque personalizado y
                nuestra atención individualizada nos permiten adaptar cada tratamiento a tus necesidades específicas.
                Nuestro objetivo es superar tus expectativas y ayudarte a alcanzar tus metas de salud y belleza.
              </p>
              <p>
                ¡No esperes más para comenzar tu viaje hacia una vida más saludable y plena! Contáctanos hoy mismo para
                programar una consulta y descubrir cómo el <span class="p-text-bold">Centro Médico Beatriz Ayllón</span> puede
                ayudarte a alcanzar tu mejor versión.
              </p>
            </template>

            <template #footer>
              <span>Para más información no dude en ponerse en contacto con nuestro
                Centro médico al 637 857 384.</span>
            </template>
          </Card>
        </div>
      </div>



      <div class="p-grid p-d-flex p-jc-around">
        <div class="p-col-12 p-d-flex p-jc-center p-text-center p-mt-3">
          <h2 class="title-bold">Algunas de nuestras especialidades</h2>
        </div>
        <!-- IMAGEN 1 -->
        <div class="p-md-6 p-d-flex p-jc-center">
          <router-link to="/Micropigmentacion" class="text-decoration-none">
            <Card>
              <template #header>
                <img alt="micropigmentacion" src="../assets/portada/cejas.jpg" class="p-mb-5 img-transition">
              </template>
              <template #content>
                <p>
                  Descubre la técnica de diseño de cejas para <span class="p-text-bold">microblading</span>, una solución
                  revolucionaria para lograr cejas perfectas y naturales. Nuestro equipo de expertos utiliza herramientas
                  de
                  última generación y técnicas precisas para crear el diseño ideal que se adapte a tu rostro y resalte tu
                  belleza única.
                </p>
                <p>
                  Con el <span class="p-text-bold">microblading</span>, podemos mejorar la forma, el grosor y el color de
                  tus cejas, creando trazos finos y delicados que imitan el aspecto de los vellos naturales. Este
                  procedimiento semipermanente es seguro y eficaz, brindando resultados duraderos y realistas.
                </p>
                <p>
                  Nuestro enfoque personalizado y profesional nos permite analizar tus características faciales y tus
                  preferencias individuales para crear un diseño de cejas que realce tus rasgos y te brinde una apariencia
                  impecable. Confía en nuestro equipo de expertos en <span class="p-text-bold">microblading</span> para
                  obtener unas cejas perfectas y definidas.
                </p>
                <p>
                  ¡Resalta tu belleza natural con nuestra técnica de diseño de cejas para <span
                    class="p-text-bold">microblading</span>! Contáctanos hoy mismo para programar tu cita y descubre la
                  transformación que podemos lograr juntos.
                </p>
              </template>
            </Card>
          </router-link>
        </div>
        <!-- IMAGEN 2 -->
        <!-- <div class="p-md-6 p-d-flex p-jc-center">
          <router-link to="/Micropigmentacion" class="text-decoration-none">
            <Card>
              <template #header>
                <img alt="user header" src="../assets/portada/cejas-grandes.jpg" class="p-mb-5">
              </template>
              <template #content>
                <p>
                  La técnica de agrandamiento de cejas con <span class="p-text-bold">microblading</span>, una
                  solución innovadora para lograr unas cejas más voluminosas y definidas. Nuestro equipo de expertos en
                  microblading utiliza herramientas de alta precisión para crear trazos finos y naturales que darán a tus
                  cejas un aspecto más lleno y atractivo.
                </p>
                <p>
                  Podemos <span class="p-text-bold">aumentar el grosor y la forma de tus cejas</span>,
                  creando un diseño personalizado que se adapte a tus características faciales y resalte tu belleza única.
                  Utilizamos pigmentos de alta calidad que se mezclan cuidadosamente para lograr el color perfecto y un
                  aspecto natural.
                </p>
                <p>
                  Nuestra experiencia nos permite evaluar tus necesidades y preferencias individuales
                  para crear unas cejas agrandadas que enmarquen tu rostro de manera armoniosa. Confía en nuestro equipo
                  de
                  expertos en <span class="p-text-bold">microblading</span> para obtener unas cejas más llenas y hermosas.
                </p>
                <p>
                  Mejora la apariencia de tus cejas con nuestra técnica de <span class="p-text-bold">microblading</span>,
                  resaltando tu mirada de manera natural y duradera. No dudes en contactarnos hoy mismo para agendar una
                  consulta y descubrir cómo podemos transformar tus cejas de forma increíble.
                </p>
              </template>
            </Card>
          </router-link>
        </div> -->

        <!-- IMAGEN 3 -->

        <div class="p-md-6 p-d-flex p-jc-center">
          <router-link to="/Micropigmentacion" class="text-decoration-none">
            <Card>
              <template #header>
                <img class=" p-mb-5 img-transition" alt="microblading linea de ojos" src="../assets/portada/linea-ojos.jpg" />
              </template>
              <template #content>
                <p>
                  ¿Quieres resaltar la belleza de tus ojos de una manera natural y duradera? Descubre nuestra técnica de
                  <span class="p-text-bold">micropigmentación</span> para la línea de ojos. Con la micropigmentación, podemos
                  realzar
                  y definir tus ojos de manera precisa y personalizada, creando una apariencia de cejas perfectas y
                  naturales.
                </p>
                <p>
                  Nuestro equipo de expertos en <span class="p-text-bold">micropigmentación</span> utiliza técnicas avanzadas y
                  herramientas especializadas para crear una línea de ojos impecable. El proceso comienza con una consulta
                  detallada para entender tus deseos y características únicas. Luego, aplicamos anestesia local para
                  garantizar tu comodidad durante el procedimiento.
                </p>
                <p>
                  Utilizando pigmentos de alta calidad y una técnica meticulosa, dibujamos trazos finos y precisos a lo
                  largo de la línea de tus ojos, imitando el aspecto de las pestañas naturales. Esto crea una apariencia
                  de
                  ojos definidos y expresivos, sin la necesidad de maquillaje diario.
                </p>
                <p>
                  Nuestro enfoque se basa en la atención al detalle y la personalización. Adaptamos el color, la forma y
                  el
                  grosor de la línea de ojos para que se ajusten a tus características faciales y preferencias
                  individuales.
                  El resultado es una línea de ojos natural y armoniosa que realza tu belleza única.
                </p>
              </template>
            </Card>
          </router-link>
        </div>

        <!-- IMAGEN 4 -->

        <div class="p-md-6 p-d-flex p-jc-center">
          <router-link to="/Micropigmentacion" class="text-decoration-none">
            <Card>
              <template #header>
                <img class="img-transition p-mb-5" alt="micropigmentacion de labios"
                  src="../assets/portada/micropigmentacion-labios.jpg" />
              </template>
              <template #content>
                <p>
                  ¿Quieres lucir unos labios perfectos y definidos en todo momento? Descubre nuestra técnica de <span
                    class="p-text-bold">micropigmentación de labios</span>. Con la micropigmentación, podemos realzar y
                  dar
                  forma a tus labios, creando un aspecto <span class="p-text-bold">natural</span> y <span
                    class="p-text-bold">duradero</span>.
                </p>
                <p>
                  Durante el procedimiento de micropigmentación, aplicamos <span class="p-text-bold">anestesia
                    local</span>
                  para garantizar tu <span class="p-text-bold">comodidad</span>. Luego, utilizamos una técnica precisa
                  para
                  depositar pigmentos en la capa superior de la piel, creando una apariencia de labios definidos y con
                  mayor
                  <span class="p-text-bold">volumen</span>.
                </p>
                <p>
                  Adaptamos el <span class="p-text-bold">color</span> y la <span class="p-text-bold">forma</span> de la
                  micropigmentación de labios para que se ajusten a tus características faciales y preferencias <span
                    class="p-text-bold">individuales</span>. El resultado es unos labios con un aspecto <span
                    class="p-text-bold">natural</span> y <span class="p-text-bold">rejuvenecido</span>, sin la necesidad
                  de
                  maquillaje diario.
                </p>
                <p>
                  Nuestra micropigmentación de labios es una solución <span class="p-text-bold">duradera</span> y de <span
                    class="p-text-bold">bajo mantenimiento</span> para realzar tu belleza <span
                    class="p-text-bold">natural</span>. ¡No dudes en contactarnos y descubre cómo podemos transformar tus
                  labios de manera <span class="p-text-bold">segura</span> y <span class="p-text-bold">efectiva</span>!
                </p>
              </template>
            </Card>
          </router-link>
        </div>


        <!-- IMAGEN 5 -->

        <div class="p-md-6 p-d-flex p-jc-center">
          <router-link to="/verrugas" class="text-decoration-none">
            <Card>
              <template #header>
                <img class="img-transition img-mosaico p-mb-5" alt="eliminacion de verrugas"
                  src="../assets/portada/verrugas.jpg" />
              </template>
              <template #content>
                <p>
                  Elimina tus verrugas de forma segura y efectiva con el <span class="p-text-bold">Plasma Pen</span>. En
                  nuestro centro especializado, ofrecemos un tratamiento innovador que eliminará por completo las verrugas
                  no deseadas, dejando la piel suave y sin imperfecciones.
                </p>
                <p>
                  El tratamiento con <span class="p-text-bold">Plasma Pen</span> comienza con una evaluación detallada de
                  las verrugas en consulta. Mediante una pluma de plasma, se realiza una quemadura controlada que elimina
                  las verrugas de manera precisa y sin dañar la piel circundante. En algunos casos, se puede aplicar un
                  anestésico para mayor comodidad del paciente, aunque la molestia durante el tratamiento es mínima.
                </p>
                <p>
                  Después del tratamiento, la piel tratada puede presentar enrojecimiento y sensibilidad durante unos
                  días.
                  En algunos casos, pueden formarse ampollas, pero estas desaparecerán con el tiempo. La verruga tratada
                  se
                  secará y caerá de forma natural, dejando una piel sana y sin rastro de la verruga.
                </p>
                <p>
                  Nuestro tratamiento con <span class="p-text-bold">Plasma Pen</span> ofrece resultados duraderos y una
                  recuperación rápida. Podrás retomar tus actividades diarias sin interrupciones. No esperes más para
                  deshacerte de las verrugas de manera segura y efectiva. ¡Contáctanos y descubre cómo el <span
                    class="p-text-bold">Plasma Pen</span> puede transformar tu piel!
                </p>
              </template>
            </Card>
          </router-link>
        </div>


        <!-- IMAGEN 6 -->

        <div class="p-md-6 p-d-flex p-jc-center">
          <router-link to="/laser" class="text-decoration-none">
            <Card>
              <template #header>
                <img class="img-transition p-mb-5" alt="laser de diodo" src="../assets/laser.jpg" />
              </template>
              <template #content>
                <p>
                  La depilación láser con la máquina <span class="p-text-bold">Fiber Pro 2.0</span> es un método
                  revolucionario y eficaz para eliminar el vello no deseado de forma permanente. Este avanzado sistema
                  utiliza tecnología láser de última generación para ofrecer resultados óptimos y duraderos.
                </p>
                <p>
                  Con la máquina <span class="p-text-bold">Fiber Pro 2.0</span>, se emite un haz de luz láser altamente
                  concentrado que es absorbido por el pigmento del vello. Esto genera un calor intenso que daña el
                  folículo
                  piloso, impidiendo su regeneración y logrando una reducción significativa del vello no deseado.
                </p>
                <p>
                  La depilación láser con <span class="p-text-bold">Fiber Pro 2.0</span> es segura y precisa, ya que el
                  láser se ajusta según el tipo de piel y color del vello. Esto permite tratar diferentes áreas del cuerpo
                  de manera efectiva, incluyendo el rostro, las piernas, el bikini y las axilas.
                </p>
                <p>
                  A diferencia de otros métodos de depilación, la depilación láser con <span class="p-text-bold">Fiber Pro
                    2.0</span> ofrece resultados a largo plazo. Con varias sesiones, el vello se vuelve más fino y escaso,
                  hasta que finalmente desaparece. Además, el proceso es prácticamente indoloro y no requiere tiempo de
                  recuperación.
                </p>
                <p>
                  Si estás buscando una solución duradera y eficiente para eliminar el vello no deseado, la depilación
                  láser
                  con la máquina <span class="p-text-bold">Fiber Pro 2.0</span> es la opción ideal. ¡Contáctanos y
                  descubre
                  cómo puedes disfrutar de una piel suave y sin vello de forma segura y efectiva!
                </p>
              </template>
            </Card>
          </router-link>
        </div>

        <!-- IMAGEN 7 -->

        <div class="p-md-6 p-d-flex p-jc-center">
          <router-link to="/hilosPdo" class="text-decoration-none">
            <Card>
              <template #header>
                <img class="img-transition p-mb-5" alt="hilos tensores" src="../assets/4.hilos-tensores.png" />
              </template>
              <template #content>
                <p>
                  Los <span class="p-text-bold">hilos tensores</span> son una técnica innovadora y efectiva para combatir
                  la
                  flacidez facial y corporal. Con este procedimiento, se utilizan <span class="p-text-bold">hilos
                    especiales</span> que se insertan en la piel para tensar y levantar los tejidos, logrando un efecto de
                  rejuvenecimiento sin cirugía.
                </p>
                <p>
                  Los hilos tensores están fabricados con <span class="p-text-bold">materiales biocompatibles</span> que
                  se
                  reabsorben con el tiempo, estimulando la producción de <span class="p-text-bold">colágeno</span> y <span
                    class="p-text-bold">elastina</span> en la zona tratada. Esto ayuda a mejorar la <span
                    class="p-text-bold">firmeza</span> y <span class="p-text-bold">elasticidad</span> de la piel,
                  brindando
                  resultados naturales y duraderos.
                </p>
                <p>
                  El procedimiento de los hilos tensores es rápido y seguro. Se realiza bajo <span
                    class="p-text-bold">anestesia local</span> y no requiere tiempo de recuperación prolongado. Los hilos
                  se
                  insertan estratégicamente en la piel, creando un efecto <span class="p-text-bold">lifting</span>
                  inmediato
                  y estimulando la <span class="p-text-bold">regeneración celular</span> a largo plazo.
                </p>
                <p>
                  Los hilos tensores se pueden aplicar en diferentes zonas del cuerpo, como el rostro, el cuello, los
                  brazos
                  y los glúteos, para tratar la flacidez y mejorar la apariencia de la piel. Los resultados son visibles
                  de
                  forma inmediata y se intensifican con el paso del tiempo, ya que los hilos estimulan la producción de
                  colágeno.
                </p>
                <p>
                  Si estás buscando una solución no quirúrgica para combatir la flacidez y rejuvenecer tu piel, los hilos
                  tensores son una excelente opción. ¡Contáctanos y descubre cómo puedes disfrutar de un aspecto más firme
                  y
                  rejuvenecido sin cirugía!
                </p>
              </template>
            </Card>
          </router-link>
        </div>

        <!-- IMAGEN 8 -->

        <div class="p-md-6 p-d-flex p-jc-center">
          <router-link to="/varices" class="text-decoration-none">
            <Card>
              <template #header>
                <img class="img-transition p-mb-5" alt="eliminacion de varices" src="../assets/portada/varices.jpg" />
              </template>
              <template #content>
                <p>
                  El tratamiento médico para eliminar las <span class="p-text-bold">venas varicosas</span> es una opción
                  efectiva y segura. Consiste en la inyección de una <span class="p-text-bold">solución especial</span> en
                  las venas afectadas, lo que provoca su cierre y mejora la circulación sanguínea.
                </p>
                <p>
                  Esta solución irrita la <span class="p-text-bold">pared interna</span> de las venas, desencadenando una
                  respuesta inflamatoria controlada. Como resultado, se forma un <span class="p-text-bold">coágulo</span>
                  en
                  la vena, lo que permite su desaparición gradual y la reducción de los síntomas asociados.
                </p>
                <p>
                  Es importante destacar que este tratamiento debe ser realizado por <span
                    class="p-text-bold">profesionales
                    médicos especializados</span> en el cuidado de las venas. El procedimiento es seguro y se lleva a cabo
                  en <span class="p-text-bold">consultorios médicos</span> o <span class="p-text-bold">clínicas
                    especializadas</span>.
                </p>
                <p>
                  Si estás buscando una solución para tratar tus <span class="p-text-bold">venas varicosas</span>, no
                  dudes
                  en consultar con un <span class="p-text-bold">especialista</span>. Ellos evaluarán tu caso de manera
                  individualizada y te ofrecerán la mejor opción de tratamiento para ti.
                </p>
              </template>
            </Card>
          </router-link>
        </div>

        <!-- IMAGEN 9 -->

        <div class="p-md-6 p-d-flex p-jc-center">
          <router-link to="/psicologia" class="text-decoration-none">
            <Card>
              <template #header>
                <img class="img-transition p-mb-5" alt="psicologia ayuda" src="../assets/portada/psicologia.jpg" />
              </template>
              <template #content>
                <p>
                  La <span class="p-text-bold">psicología</span> es una herramienta poderosa para el crecimiento personal
                  y
                  el bienestar emocional. Como <span class="p-text-bold">psicólogos profesionales</span>, nuestro objetivo
                  es ayudarte a superar
                  los desafíos y dificultades que puedas enfrentar, brindándote apoyo y orientación en tu camino hacia una
                  vida más plena y satisfactoria.
                </p>
                <p>
                  A través de la <span class="p-text-bold">terapia psicológica</span>, podemos explorar tus pensamientos,
                  emociones y comportamientos, identificando patrones negativos y desarrollando estrategias efectivas para
                  superarlos. La psicología te ofrece un espacio seguro y confidencial donde puedes expresarte libremente
                  y
                  trabajar en el desarrollo de habilidades para enfrentar los obstáculos de la vida.
                </p>
                <p>
                  Los beneficios de la <span class="p-text-bold">psicología</span> van más allá de la resolución de
                  problemas. También te ayuda a mejorar tu <span class="p-text-bold">autoconocimiento</span>, fortalecer
                  tus
                  <span class="p-text-bold">relaciones interpersonales</span> y potenciar tu <span
                    class="p-text-bold">crecimiento personal</span>. Aprenderás a manejar el estrés, regular tus emociones
                  y
                  mejorar tu <span class="p-text-bold">autoestima</span>, lo que te permitirá tomar decisiones más
                  saludables y alcanzar tus metas de manera más efectiva.
                </p>
                <p>
                  Nuestro <span class="p-text-bold">enfoque es personalizado y basado en tus necesidades
                    individuales</span>. Trabajaremos juntos para identificar tus metas y diseñar un plan de tratamiento
                  que
                  se
                  adapte a ti. El objetivo es brindarte las herramientas y estrategias necesarias para que puedas
                  enfrentar
                  los desafíos de la vida de manera más efectiva y alcanzar un mayor <span class="p-text-bold">bienestar
                    emocional</span>.
                </p>
                <p>
                  Si estás buscando un espacio de apoyo y crecimiento personal, no dudes en contactarnos. Estamos aquí
                  para
                  ayudarte en tu camino hacia una vida más plena y satisfactoria. ¡Juntos podemos lograr un cambio
                  positivo
                  en tu vida!
                </p>
              </template>
            </Card>
          </router-link>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import Card from 'primevue/card';
export default {
  name: 'HomeVue',
  data() {
    return {
      // Propiedades de datos del componente
    };
  },
  components: {
    Card
  },
  methods: {
    // Métodos del componente
  },
  computed: {
    // Propiedades computadas del componente
  },
  created() {
    // Lógica que se ejecuta cuando el componente se crea
  },
  mounted() {
    // Lógica que se ejecuta cuando el componente se monta en el DOM
  },
  destroyed() {
    // Lógica que se ejecuta cuando el componente se destruye
  },
};
</script>

<style scoped>
.title-bold {
  font-size: 30px;
  font-weight: bold
}

/* SE C0OMENTA POR NO SABER PARA QUE SE USA */

.img-fluid {
  width: 100%;
  height: auto;
} 

.icon-size {
  width: 30px;
}

.text-decoration-none {
  text-decoration: none;
  width: 90%
}

.img-transition {
  border-radius: 15px;
  float: left;
  -webkit-transition: margin 0.5s ease-out;
  -moz-transition: margin 0.5s ease-out;
  -o-transition: margin 0.5s ease-out;
  box-shadow: 6px 6px 9px rgba(0, 0, 0, 0.3);
  width: 90%;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
}

.img-transition:hover {
  margin-top: 17px;
}
</style>
