import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  // mode: "history",

  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // MEDICINA ESTETICA FACIAL
  {
    path: "/hilosPdo",
    name: "PDO",
    component: () =>
      import("../views/Medicina-estetica-facial/HilosTensores.vue"),
  },
  {
    path: "/hialuronico",
    name: "Hialuronico",
    component: () =>
      import("../views/Medicina-estetica-facial/AcidoHialuronico.vue"),
  },
  {
    path: "/hidroxiapatita",
    name: "Hidroxiapatita",
    component: () =>
      import("../views/Medicina-estetica-facial/Hidroxiapatita.vue"),
  },
  {
    path: "/rinomodelacion",
    name: "Rinomodelacion",
    component: () =>
      import("../views/Medicina-estetica-facial/Rinomodelacion.vue"),
  },
  {
    path: "/peeling",
    name: "Peeling",
    component: () => import("../views/Medicina-estetica-facial/Peeling.vue"),
  },
  {
    path: "/revitalizacion",
    name: "Revitalizacion",
    component: () =>
      import("../views/Medicina-estetica-facial/Revitalizacion.vue"),
  },
  {
    path: "/verrugas",
    name: "Verrugas",
    component: () => import("../views/Medicina-estetica-facial/Verrugas.vue"),
  },
  {
    path: "/cabello",
    name: "Cabello",
    component: () =>
      import("../views/Medicina-estetica-corporal/MesoterapiaCabello.vue"),
  },
  {
    path: "/expresion",
    name: "Expresion",
    component: () =>
      import("../views/Medicina-estetica-corporal/Expresion.vue"),
  },

  // MEDICINA ESTETICA CORPORAL
  {
    path: "/intralipoterapia",
    name: "Intralipoterapia",
    component: () =>
      import("../views/Medicina-estetica-corporal/Intralipoterapia.vue"),
  },
  {
    path: "/hilosCorporales",
    name: "HilosCorporales",
    component: () =>
      import("../views/Medicina-estetica-corporal/HilosCorporales.vue"),
  },
  {
    path: "/gluteos",
    name: "Gluteos",
    component: () => import("../views/Medicina-estetica-corporal/Gluteos.vue"),
  },
  {
    path: "/presoterapia",
    name: "Presoterapia",
    component: () =>
      import("../views/Medicina-estetica-corporal/Presoterapia.vue"),
  },
  {
    path: "/modelado",
    name: "Modelado",
    component: () =>
      import("../views/Medicina-estetica-corporal/ModeladoCorporal.vue"),
  },
  {
    path: "/varices",
    name: "Varices",
    component: () => import("../views/Medicina-estetica-corporal/Varices.vue"),
  },
  {
    path: "/bruxismo",
    name: "Bruxismo",
    component: () => import("../views/Medicina-estetica-corporal/Bruxismo.vue"),
  },
  {
    path: "/hiperhidrosis",
    name: "Hiperhidrosis",
    component: () =>
      import("../views/Medicina-estetica-corporal/Hiperhidrosis.vue"),
  },
  {
    path: "/fisioterapia",
    name: "Fisioterapia",
    component: () => import("../views/Fisioterapia/Fisioterapia.vue"),
  },
  {
    path: "/laser",
    name: "Laser",
    component: () => import("../views/Laser/laser.vue"),
  },
  {
    path: "/micropigmentacion",
    name: "Micropigmentacion",
    component: () => import("../views/Micropigmentacion/Micro.vue"),
  },
  {
    path: "/psicologia",
    name: "Psicologia",
    component: () => import("../views/Psicologia/psico.vue"),
  },
  {
    path: "/privacidad",
    name: "Privacidad",
    component: () => import("../views/Politicas/Privacidad.vue"),
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: () => import("../views/Politicas/Cookies.vue"),
  },
  {
    path: "/somos",
    name: "QuienesSomos",
    component: () => import("../views/Quienes-somos/QuienesSomos.vue"),
  },
];
// scrollBehavior() {
//   document.getElementById("general-div").scrollIntoView();
// },
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
