<template>
  <div id="app" class="general-class">
    <NavBar class="NavBar" />
    <div class="altura" id="general-div">
      <router-view />
    </div>
    <Footer class="Footer" />
  </div>
</template>
<script>
// @ is an alias to /src
import NavBar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Nav',
  components: {
    NavBar,
    Footer
  }
}
</script>
<style>
@import url("../node_modules/primevue/resources/themes/saga-blue/theme.css");
@import url("../node_modules/primevue/resources/primevue.min.css");
@import url("../node_modules/primeicons/primeicons.css");

.grid {
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* Define una cuadrícula con tres filas: Navbar, contenido y Footer */
  min-block-size: 00vh;
  /* Establece una altura mínima para que la cuadrícula ocupe todo el alto de la pantalla */
}

.NavBar {
  position: fixed;
  /* Fija el Navbar en la parte superior */
  inset-block-start: 0;
  /* Asegura que esté siempre en la parte superior */
  inline-size: 100%;
  /* Ocupa todo el ancho disponible */

  padding-top: 7px;
}

.Footer {
  position: fixed;
  /* Fija el Footer en la parte inferior */
  inset-block-end: 0;
  /* Asegura que esté siempre en la parte inferior */
  inline-size: 100%;
  /* Ocupa todo el ancho disponible */
}

.general-class {
  font-family: 'Arial', sans-serif;
  padding-block-start: 70px;
  /* Altura de la cabecera */
  padding-block-end: 90px;
  /* Altura del footer */
  max-block-size: calc(100vh - 20px);
  /* Altura mínima del cuerpo */
  overflow-x: hidden;
}

.p-menubar {

    border: 0; 
    border-radius: 0;
}

.size-footer {
    margin: 0;
}

body {
  margin: 0px;
}

@media screen and (max-width: 768px) {

  /* Estilos para pantallas medianas y más grandes */
  /* Agrega aquí tus estilos específicos */
  .altura {
    padding-bottom: 2px;
  }
}

@media screen and (min-width: 768px) {

  /* Estilos para pantallas medianas y más grandes */
  /* Agrega aquí tus estilos específicos */
  .altura {
    padding-bottom: 1px;
  }
}

/* Estilos para la barra de desplazamiento */
::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}



/* Estilos para el pulgar de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background-color: #75bef8; /* Color del pulgar */
  border-radius: 20px;
  height: 15px;
}


</style>
