<template>
    <div>
        <div class="p-grid p-mb-3 p-mr-5">
            <div class="p-d-none p-md-12 p-d-md-block">
                <div class="p-d-flex p-jc-end p-mt-2">
                    <a target="_blank" href="https://www.facebook.com/psicoeducaformacion/"><img class="p-mr-3 icon-size"
                            alt="user header" src="../assets/facebook.png"></a>
                    <a target="_blank"
                        href="https://instagram.com/centro_medico_beatriz_ayllon_?utm_source=qr&igshid=OGU0MmVlOWVjOQ=="><img
                            class="p-mr-3 icon-size" alt="user header" src="../assets/instagram.png"></a>
                    <a target="_blank" href="https://www.tiktok.com/@beatrizayllondelafuente"><img class="p-mr-3 icon-size"
                            alt="user header" src="../assets/tik-tok.png"></a>
                    <a target="_blank" href="mailto:info@cmedicointegral.com"><i>
                            <img class="icon-size p-mr-3" src="../assets/email.png">
                        </i>
                    </a>
                    <a target="_blank" href="tel:+63785784">
                        <i>
                            <img class="icon-size" src="../assets/whatsapp.png">
                        </i>
                    </a>
                </div>
            </div>
        </div>
        <div class="p-grid gradient">
            <div class="p-col-12 p-md-4 p-text-center">
                <router-link class="text-decoration-none" to="/cookies">
                    <p class="">Política de cookies</p>
                </router-link>
                <router-link class="text-decoration-none" to="/privacidad">
                    <p class="">Política de privacidad</p>
                </router-link>
                <router-link class="text-decoration-none" to="/somos">
                    <p class=" p-d-md-none">Quiénes somos</p>
                </router-link>

                <p class=" p-d-md-none">637 857 384</p>
                <p class=" p-d-md-none">info@cmedicointegral.com</p>
            </div>

            <div class="p-d-none p-md-4 p-d-md-block p-text-center">
                <router-link class="text-decoration-none" to="/somos">
                    <p class="">Quiénes somos</p>
                </router-link>
                <p class="">637 857 384</p>
            </div>
            <div class="p-d-none p-md-4 p-d-md-block p-text-center">
                <p class="">info@cmedicointegral.com</p>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
/* Estilos específicos del componente */
.gradient {
    background: linear-gradient(to right, #08cae8, #0c7ec4);
}

.icon-size {
    width: 25px;
}

p {
    color: white;
    margin: 0;
}

.text-decoration-none {
    text-decoration: none;
}
</style>

