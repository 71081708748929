import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.min.css';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';

Vue.config.productionTip = false
Vue.component('InputText', InputText);

new Vue({
  router,
  store,
  PrimeVue,
  Card,
  render: function (h) { return h(App) }
}).$mount('#app')


