<template>
  <div>

    <div class="p-grid p-mr-5">
      <div class="p-col-6 p-offset-6 p-d-sm-block p-d-md-none icon-position">
        <div class="p-d-flex p-jc-end p-mt-2 p-mr-5">
          <a target="_blank" href="https://www.facebook.com/psicoeducaformacion/"><img class="p-mr-3 icon-size"
              alt="user header" src="../assets/facebook.png"></a>
          <a target="_blank"
            href="https://instagram.com/centro_medico_beatriz_ayllon_?utm_source=qr&igshid=OGU0MmVlOWVjOQ=="><img
              class="p-mr-3 icon-size" alt="user header" src="../assets/instagram.png"></a>
          <a target="_blank" href="https://www.tiktok.com/@beatrizayllondelafuente"><img class="p-mr-3 icon-size"
              alt="user header" src="../assets/tik-tok.png"></a>
          <a target="_blank" href="mailto:info@cmedicointegral.com"><i>
              <img class="icon-size p-mr-3" src="../assets/email.png">
            </i>
          </a>
          <a target="_blank" href="tel:+637857384">
            <i>
              <img class="icon-size" src="../assets/whatsapp.png">
            </i>
          </a>
        </div>
      </div>
    </div>
    <Menubar :model="items" class="navbar nav-position">
      <template #start>
        <!-- <img alt="logo" src="../../assets/images/logo.svg" height="40" class="mr-2"> -->
      </template>
    </Menubar>
  </div>
</template>

<script>
import Menubar from 'primevue/menubar';
export default {
  name: 'NavbarVue',
  components: {
    Menubar
  },
  props: {
    msg: String
  },
  data() {
    return {
      items: [
        {
          label: 'Inicio', to: '/'
        },
        {
          label: 'Medicina estética facial',
          items: [
            {
              label: 'Hilos tensores PDO', to: '/hilosPdo'
            },
            {
              label: 'Acido hialuronico', to: '/hialuronico'
            },
            {
              label: 'Hidroxiapatita cálcica', to: '/hidroxiapatita'
            },
            {
              label: 'Mesoterapia capilar', to: '/cabello'
            },
            {
              label: 'Rinomodelación', to: '/rinomodelacion'
            },
            {
              label: 'Peeling PRX-T33', to: '/peeling'
            },
            {
              label: 'Revitalización con vitaminas de ácido hialurónico', to: '/revitalizacion'
            },
            {
              label: 'Eliminación de verrugas con plasma pen', to: '/verrugas'
            },

          ]
        },
        {
          label: 'Medicina estética corporal',
          items: [
            {
              label: 'Mesoterapia e intralipoterapia corporal', to: '/intralipoterapia'
            },
            // {
            //   label: 'Tratamiento de mesoterapia capilar', to: '/cabello'
            // },
            {
              label: 'Hilos corporales', to: '/hilosCorporales'
            },
            {
              label: 'Aumento de glúteos sin cirugía', to: '/gluteos'
            },
            {
              label: 'Presoterapia', to: '/presoterapia'
            },
            {
              label: 'Modelado corporal con Muscle sculp', to: '/modelado'
            },
            {
              label: 'Varices y arañas vasculares', to: '/varices'
            },
          ]
        },
        {
          label: 'Neuromoduladores',
          items: [
            {
              label: 'Neuromoduladores para arrugas de expresión', to: '/expresion'
            },
            {
              label: 'Neuromoduladores para el bruxismo', to: '/bruxismo'
            },
            {
              label: 'Neuromoduladores para la hiperhidrosis', to: '/hiperhidrosis'
            },

          ]
        },
        {
          label: 'Fisioterapia y suelo pélvico', to: '/fisioterapia'
        },
        {
          label: 'Laser diodo', to: '/laser'
        },
        {
          label: 'Micropigmentación y microblading', to: '/micropigmentacion'
        },
        {
          label: 'Psicología', to: '/psicologia'
        },
        {
          label: 'Ofertas', url: 'https://www.cmedicointegral.com/ofertas'
        },
      ]
    }
  }
}
</script>

<style scoped>
.navbar {
  background: linear-gradient(to right, #08cae8, #0c7ec4);
}

.navbar .p-menuitem-link:hover {
  background-color: #0c7ec4;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.icon-size {
  width: 25px;
}

.nav-position {
  position: relative;
  z-index: 1;
}

.icon-position {
  position: absolute;
  top: 10;
  left: 0;
  z-index: 2;
}
</style>
<style>
@media screen and (min-width: 768px) {
  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    color: white !important;
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:hover .p-menuitem-text {
    color: black !important;
    /* Color al pasar el ratón */
  }

}
</style>